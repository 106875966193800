
import BarcodeIcon from '@/components/BarcodeIcon.vue'
import BarcodePDF from '@/components/barcodeImport/BarcodePDF.vue'
import SearchForm from '@/components/barcodeSearch/SearchForm.vue'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import IconDownload from '@/components/svg/IconDownload.vue'
import IconSearch from '@/components/svg/IconSearch.vue'
import IconSetting from '@/components/svg/IconSetting.vue'
import { LOAD_BARCODE_LIST, LOAD_CLIENT_LIST, LOAD_PROJECTS_LIST, SET_HAVE_NEW_EXPORT_RECORD } from '@/store/actions'
import { archiveBarcodes, exportBarCode, getBarcodeList, requestBarcodeReport, setClientSearchData } from '@/utils/api'
import {
  BARCODE_SEARCH_VALUE_TYPES,
  EXPORT_BARCODES_LIMIT_NUMBER,
  TABLE_SORT_ASC,
  TABLE_SORT_DESC,
  TABLE_SORT_TYPE,
} from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import { buildSearchUrlQuery, formatSearchConditionBlocks, initDataSearch } from '@/utils/helpers'
import { openMessage, sortTrackingPoints } from '@/utils/utils'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { EExportType, ESmartBarcodeMenu, EUserType } from 'smartbarcode-web-core/src/utils/enums/index'
import { objectToArray } from 'smartbarcode-web-core/src/utils/helpers'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IBarcodeDefinitionType,
  IBarcodeList,
  IBarcodeReportTemplate,
  IBarcodeSearch,
  IBarcodeSearchForm,
  IBarcodeSearchFormItem,
  IClientList,
  IProject,
  ISearchConditionBlock,
  ITableSortData,
  ITrackpoint,
  ITrackPointKeyVal,
  TError,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options } from 'vue-class-component'
import { Mixins, ProvideReactive, Watch } from 'vue-property-decorator'
type TListType = 'searchList' | 'presetList'

export interface IClient {
  id: string
  name: string
  email: string
}

@Options({
  components: { ItemNotFound, SearchForm, BarcodeIcon, IconDownload, BarcodePDF, IconSearch, IconSetting },
  name: ESmartBarcodeMenu.BARCODE_SEARCH,
})
export default class Barcode extends Mixins(DataTableMixin) {
  @ProvideReactive() projectVersion = 0
  @ProvideReactive() trackingPointDatasArr: ITrackPointKeyVal[] = []

  isShowClientPresetDialog = false
  isShowBarcodeDisplaySetting = false
  isShowArchiveBCDialog = false

  currentPageNum = 1
  dataSearch = {} as IBarcodeSearchForm
  loading = false
  isShowTable = true
  exportBarcodes = []
  selectedClients: string[] = []

  barcodeDisplayColumns: string[] = []
  selectedBarcodeDisplayColumns: string[] = []

  get isEnableArchive(): boolean {
    return this.$store.getters.isEnableArchive
  }

  openBarcodeDialog() {
    this.selectedBarcodeDisplayColumns = JSON.parse(localStorage.getItem('barcode_table_display_columns') ?? '[]')
    this.isShowBarcodeDisplaySetting = true
  }

  saveBarcodeDisplaySettings() {
    localStorage.setItem('barcode_table_display_columns', JSON.stringify(this.selectedBarcodeDisplayColumns))
    this.barcodeDisplayColumns = [...this.selectedBarcodeDisplayColumns]
    this.isShowBarcodeDisplaySetting = false
  }

  get displayColumns() {
    return [
      { idx: 'id', label: this.$t('barcode.id') },
      { idx: 'barcodeType', label: this.$t('barcode.barcodeType') },
      { idx: 'currentTrackpoint', label: this.$t('barcode.currentTrackpoint') },
      { idx: 'createdDate', label: this.$t('barcode.createdDate') },
      { idx: 'lastTrackingDateTime', label: this.$t('barcode.lastTrackingDateTime') },
      { idx: 'status', label: this.$t('status') },
      { idx: 'report_output', label: this.$t('barcode_type.report_output') },
    ]
  }

  openArchiveDialog() {
    this.isShowArchiveBCDialog = true
  }

  openPresetDialog() {
    this.isShowClientPresetDialog = true
    this.selectedClients = []
  }

  get clients(): IClient[] {
    return ([...this.$store.state.client.clients]
      .filter((val: IClientList) => !!val.clientUser)
      .map((c: IClientList) => ({
        id: c.clientUser.id,
        name: c.client.name,
        email: c.clientUser.email,
      })) ?? []) as IClient[]
  }

  get isSetDisplayedBarcodeByClient() {
    return this.$store.state.profile?.organization?.setDisplayedBarcodeByClient || false
  }

  async fetchClients() {
    if (this.isLoggedAsClient) return
    try {
      this.loading = true
      await this.$store.dispatch(LOAD_CLIENT_LIST)
    } catch (error) {
      errorHandler(error)
    } finally {
      this.loading = false
    }
  }

  @Watch('dataSearch.version')
  updateProjectVersion() {
    this.projectVersion = this.dataSearch.version
  }

  get maxProjectVersion() {
    const project = this.getProjectsList.find((item) => item.code === this.dataSearch.projectCode)
    return project?.version ?? 1
  }

  get isSetPresetSearch() {
    return this.$store.state.profile?.user?.setPresetSearch
  }

  presetData = initDataSearch() as IBarcodeSearchForm
  async loadClientPreset() {
    if (!this.isLoggedAsClient || !this.isSetPresetSearch) return false
    const clientPreset = cloneDeep(this.$store.state.profile?.user?.condition)
    clientPreset.searchConditionBlocks.forEach(async (block: ISearchConditionBlock) => {
      // specially handle projectId
      if (block.searchConditions[0].key === 'projectId') block.searchConditions.splice(0, 1)

      block.searchConditions.forEach((condition) => {
        // specially handle subKey
        const keys = (condition.key ?? '').split('.')
        if (keys.length === 3) {
          condition.key = `${keys[0]}.${keys[1]}`
          condition.subKey = keys[2]
        }
      })
    })

    this.presetData = {
      ...this.dataSearch,
      ...clientPreset,
      projectCode: clientPreset.projectCode,
      version: clientPreset.version,
      count: 10,
    }
    this.dataSearch = {
      ...initDataSearch(),
      projectCode: clientPreset.projectCode,
      version: clientPreset.version,
    }

    return true
  }

  get totalBarcodes(): number {
    return this.$store.state.barcode?.totalBarcodes
  }

  get defaultSort() {
    return {
      prop: (this.dataSearch?.sortKey !== '_id' ? this.dataSearch?.sortKey : 'id') || null,
      order:
        this.dataSearch?.sortOrder === 1 ? TABLE_SORT_ASC : this.dataSearch?.sortOrder === -1 ? TABLE_SORT_DESC : '',
    }
  }

  get loggedUser() {
    return this.$store.state.profile?.user || {}
  }

  get isLoggedAsClient() {
    return this.loggedUser.userType === EUserType.CLIENT || false
  }

  get isCSVButtonDisable(): boolean {
    const isSearchValue = this.dataSearch?.searchConditionBlocks?.some((block) => {
      return block.searchConditions?.some(
        (val) => !isEmpty(val.value) || (!isEmpty(val.minValue) && !isEmpty(val.maxValue))
      )
    })
    return !isSearchValue || this.barcodeList.length < 1
  }

  get barcodeList(): IBarcodeList[] {
    return this.$store.state.barcode?.barcodes
  }

  get getCurrentProject(): IProject {
    return this.$store.state.barcode?.projectList
  }

  get getProjectsList(): IProject[] {
    return this.$store.state.project?.originalProjects.filter(
      (item: IProject) => !(item.isDraft && item.isDraft === true)
    )
  }

  barcodeReportTemplates(barcodeCode: string): IBarcodeReportTemplate[] {
    if (!barcodeCode) return []
    return this.getCurrentProject?.barcodeTypes?.[barcodeCode]?.barcodeReportTemplates || []
  }

  formatBarcodeType(barcodeTypeDefinition: IBarcodeDefinitionType): IBarcodeDefinitionType {
    return barcodeTypeDefinition as IBarcodeDefinitionType
  }

  async setSearchPreset2Clients() {
    this.loading = true
    const preset = cloneDeep(this.dataSearch)
    const searchConditionBlocks = formatSearchConditionBlocks(preset.searchConditionBlocks)

    const setPresetPayload = {
      isConfirm: false,
      clientIds: this.selectedClients,
      condition: { ...preset, searchConditionBlocks } as IBarcodeSearchForm,
    }
    try {
      await setClientSearchData(setPresetPayload)
    } catch (e) {
      if (Object.values(e as TError)?.[0]?.[0] === '3085') {
        this.$confirm(this.$t('confirm_overwrite_exist_client_preset'), this.$t('confirm'), {
          confirmButtonText: this.$t('ok'),
          confirmButtonClass: 'danger',
          cancelButtonText: this.$t('cancel'),
        }).then(async () => {
          await setClientSearchData({ ...setPresetPayload, isConfirm: true })
          openMessage(this.$t('set_preset_successfully'), 'success')
        })
      } else {
        errorHandler(e)
      }
    } finally {
      this.loading = false
      this.isShowClientPresetDialog = false
    }
  }

  async archiveOrUnarchive() {
    try {
      this.loading = true
      const condition = (() => {
        const condBlocks = [] as ISearchConditionBlock[]
        const searchData = cloneDeep(this.dataSearch)
        searchData.searchConditionBlocks.forEach((i) => condBlocks.push(cloneDeep(i)))

        return { ...searchData, searchConditionBlocks: formatSearchConditionBlocks(condBlocks) } as IBarcodeSearchForm
      })()

      await archiveBarcodes({
        archiveType: !this.dataSearch.isArchived ? 'archive' : 'unarchive',
        condition,
      })

      this.$store.commit(SET_HAVE_NEW_EXPORT_RECORD, { exportType: EExportType.ARCHIVE, isHaveNew: true })
      openMessage(this.$t('archive_barcode_success'), 'success')
    } catch (e) {
      errorHandler(e)
    } finally {
      this.isShowArchiveBCDialog = false
      this.loading = false
    }
  }

  listType: TListType = 'searchList'
  async fetchBarcodesList(data: IBarcodeSearchForm, type: TListType) {
    this.loading = true

    if (type === 'presetList') data = this.presetData
    this.listType = type
    this.isShowTable = false
    this.redirectIndex(data, this.currentPageNum)
    await this.$store.dispatch(LOAD_BARCODE_LIST, data).finally(() => (this.loading = false))
    setTimeout(() => (this.isShowTable = true))

    const newTPs = this.getCurrentProject.trackPoints
    if (isEmpty(newTPs)) return

    const arrTrackPoints = objectToArray<ITrackpoint>(cloneDeep(newTPs)) || []
    sortTrackingPoints(arrTrackPoints)
    this.trackingPointDatasArr = arrTrackPoints
  }

  async sortBarcode(data: ITableSortData) {
    this.dataSearch.sortKey = data.prop && data.prop !== 'id' ? data.prop : data.prop ?? '_id'
    this.dataSearch.sortOrder = TABLE_SORT_TYPE[data.order ?? TABLE_SORT_DESC]
    await this.fetchBarcodesList(this.dataSearch, this.listType)
  }

  async currentPageChange(pageNum: number) {
    if (this.isTriggerPageChanging) {
      this.currentPageNum = pageNum
      const skipNum = (Number(pageNum) - 1) * Number(this.dataSearch.count)
      this.dataSearch.skip = skipNum
      await this.fetchBarcodesList(this.dataSearch, this.listType)
    } else {
      this.isTriggerPageChanging = true
    }
  }

  async onSelectProject() {
    const dataSearch = initDataSearch()
    dataSearch.projectCode = this.dataSearch.projectCode

    const project = this.getProjectsList.find((item) => item.code === this.dataSearch.projectCode)
    dataSearch.version = project?.version ?? 1

    this.currentPageNum = 1
    await this.fetchBarcodesList(dataSearch, this.listType)
    this.dataSearch = dataSearch
  }

  async requestReport(code: string, barcodeId: string) {
    try {
      this.loading = true
      if (barcodeId) {
        await requestBarcodeReport(barcodeId, code)
      }

      this.$store.commit(SET_HAVE_NEW_EXPORT_RECORD, { exportType: EExportType.BARCODE, isHaveNew: true })
    } catch (error) {
      errorHandler(error)
    } finally {
      this.loading = false
    }
  }

  async downloadCSV() {
    try {
      const payload = cloneDeep(this.dataSearch)
      delete payload.count
      delete payload.skip
      // format dataSearch
      const searchConditionBlocks = formatSearchConditionBlocks(payload.searchConditionBlocks)
      const dataSearch = { condition: { ...payload, searchConditionBlocks } } as IBarcodeSearch
      await exportBarCode((dataSearch as unknown) as Record<string, unknown>)
      this.$store.commit(SET_HAVE_NEW_EXPORT_RECORD, { exportType: EExportType.BARCODE, isHaveNew: true })
    } catch (error) {
      openMessage(error as string, 'error')
    }
  }

  async downloadPDFBarcodes() {
    try {
      this.loading = true
      const condBlocks = [] as ISearchConditionBlock[]
      this.dataSearch.searchConditionBlocks.forEach((i) => condBlocks.push(cloneDeep(i)))
      const dataSearch = {
        condition: {
          ...this.dataSearch,
          count: EXPORT_BARCODES_LIMIT_NUMBER,
          searchConditionBlocks: formatSearchConditionBlocks(condBlocks),
        },
      } as IBarcodeSearch
      // const result = await getBarcodeList(dataSearch)
      this.exportBarcodes = (await getBarcodeList(dataSearch)) ?? []
      this.$refs.barcodePDFComponent.showComfirmPopup()
    } catch (error) {
      errorHandler(error)
    } finally {
      this.loading = false
    }
  }

  onEditBarcode(barcode: IBarcodeList) {
    this.$router.push({ name: 'barcodeView', params: { id: barcode.id } })
  }

  // this option control - turn off onPageChange event trigger at first time when searching.
  isTriggerPageChanging = true
  async performSearch() {
    this.isTriggerPageChanging = false

    this.dataSearch.skip = 0
    await this.fetchBarcodesList(this.dataSearch, 'searchList')
  }

  async clearAllSearchForm() {
    const dataSearch = {
      ...initDataSearch(),
      projectCode: this.dataSearch.projectCode,
      version: this.dataSearch.version,
    }
    this.dataSearch = dataSearch
    await this.fetchBarcodesList(dataSearch, this.listType)
  }

  initializeDisplayColumnSettings() {
    this.barcodeDisplayColumns = JSON.parse(localStorage.getItem('barcode_table_display_columns') ?? '[]')
    if (isEmpty(this.barcodeDisplayColumns)) {
      const columns = this.displayColumns.map((i) => i.idx)
      localStorage.setItem('barcode_table_display_columns', JSON.stringify([...columns]))
      this.barcodeDisplayColumns = [...columns]
      this.selectedBarcodeDisplayColumns = [...columns]
    }
  }

  async created() {
    this.initializeDisplayColumnSettings()
    await this.fetchClients()

    if (!this.$store.state.project?.isLoaded) {
      this.loading = true
      await this.$store.dispatch(LOAD_PROJECTS_LIST)
      this.loading = false
    }

    const hasQueryData = !isEmpty(this.$route.query)
    if (hasQueryData && !this.isSetPresetSearch) {
      this.loadedURLSearchData()
      await this.fetchBarcodesList(this.dataSearch, 'searchList')
    } else {
      const isLoadedPreset = await this.loadClientPreset()
      if (isLoadedPreset) {
        await this.fetchBarcodesList(this.presetData, 'presetList')
      } else {
        this.dataSearch = initDataSearch()
        this.dataSearch.projectCode = this.getProjectsList?.[0].code ?? ''
        this.dataSearch.version = this.getProjectsList.find((i) => i.code === this.dataSearch.projectCode)?.version ?? 1
        await this.fetchBarcodesList(this.dataSearch, 'searchList')
      }
    }
  }

  async onVersionChange() {
    const dataSearch = {
      ...initDataSearch(),
      projectCode: this.dataSearch.projectCode,
      version: this.dataSearch.version,
    }
    this.dataSearch = dataSearch
    await this.fetchBarcodesList(this.dataSearch, this.listType)
  }

  loadedURLSearchData() {
    const queryData = this.$route.query
    this.dataSearch = initDataSearch()
    if (queryData.projectCode) {
      for (const key in queryData) {
        switch (key) {
          case 'sortOrder':
            this.dataSearch[key] = queryData[key] === TABLE_SORT_DESC ? -1 : queryData[key] === TABLE_SORT_ASC ? 1 : 0
            break
          case 'version':
            this.dataSearch[key] = Number(queryData[key])
            break
          case 'projectCode':
          case 'logicalOperator':
          case 'sortKey':
            this.dataSearch[key] = queryData[key]
            break
          case 'page':
            if (queryData.page) {
              this.currentPageNum = Number(queryData.page)
              const skipNum = (this.currentPageNum - 1) * Number(this.dataSearch.count)
              this.dataSearch.skip = skipNum
            } else {
              this.currentPageNum = 1
            }
            break
          default:
            this.dataSearch = this.getSearchConditionBlocks(this.dataSearch, queryData, key)
            break
        }
      }
    }
  }

  getSearchConditionBlocks(dataSearch: IBarcodeSearchForm, queryData: Record<string, string>, key: string) {
    const [mainKey, conditionIdx, blockIdx] = key.split('_')

    const idxNum = Number(conditionIdx)
    const blockIdxNum = Number(blockIdx)

    if (!dataSearch.searchConditionBlocks[blockIdxNum]) {
      dataSearch.searchConditionBlocks[blockIdxNum] = { searchConditions: [] }
    }
    const block = dataSearch.searchConditionBlocks[blockIdxNum]
    if (mainKey) {
      if (mainKey === 'logicalOperator') {
        block.logicalOperator = queryData[key]
      } else if (mainKey === 'valueList') {
        const valueList = queryData[key].split(',').map((item) => item.trim())
        block.searchConditions[idxNum].valueList = valueList as string[]
      } else {
        if (!block.searchConditions?.[idxNum]) block.searchConditions[idxNum] = {} as IBarcodeSearchFormItem

        block.searchConditions[idxNum][
          mainKey as 'key' | 'subKey' | 'value' | 'minValue' | 'maxValue' | 'valueType' | 'bcTypeKey' | 'componentType'
        ] = queryData[key]
      }
    }

    block.searchConditions.map((item: IBarcodeSearchFormItem) => {
      if (item.valueType === BARCODE_SEARCH_VALUE_TYPES.date) {
        if (item.value) item.value = moment(item.value).format()
        if (item.minValue) item.minValue = moment(item.minValue).format()
        if (item.maxValue) item.maxValue = moment(item.maxValue).format()
        if (item.minValue && item.maxValue) {
          item.value = ([item.minValue, item.maxValue] as unknown) as string
        }
      }
    })

    return dataSearch
  }

  redirectIndex(data: IBarcodeSearchForm, page: number) {
    this.$router.push({ name: this.routeName, query: buildSearchUrlQuery(data, page) })
  }
}
